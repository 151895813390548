<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4 lg3 xl2 class="text-xs-center">
          <div class="404">
            <h1 class="headline"> Esta pagina no existe </h1>
            <v-btn class="accent" href="/"> Volver </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
};
</script>
